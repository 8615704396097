import React from 'react';
import styled from 'styled-components';

function getIcon(a) {
	switch (a) {
		case "user":
			return(
				<svg width="18" height="20" viewBox="0 0 77 85" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M72.4804 81.041V72.4809C72.4804 67.9404 70.6767 63.5858 67.466 60.3752C64.2554 57.1646 59.9008 55.3608 55.3603 55.3608H21.1201C16.5796 55.3608 12.225 57.1646 9.01436 60.3752C5.80372 63.5858 4 67.9404 4 72.4809V81.041" stroke="black" strokeWidth="7.70404" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M38.2402 38.2402C47.6954 38.2402 55.3603 30.5753 55.3603 21.1201C55.3603 11.6649 47.6954 4 38.2402 4C28.785 4 21.1201 11.6649 21.1201 21.1201C21.1201 30.5753 28.785 38.2402 38.2402 38.2402Z" stroke="black" strokeWidth="7.70404" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>
			)
		// break;
		case "email":
			return(
				<svg width="20" height="16.7" viewBox="0 0 85 71" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M11.6376 4.7041H73.27C77.5072 4.7041 80.974 8.17092 80.974 12.4081V58.6324C80.974 62.8696 77.5072 66.3364 73.27 66.3364H11.6376C7.40041 66.3364 3.93359 62.8696 3.93359 58.6324V12.4081C3.93359 8.17092 7.40041 4.7041 11.6376 4.7041Z" stroke="black" strokeWidth="7.70404" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M80.973 12.4082L42.4528 39.3723L3.93262 12.4082" stroke="black" strokeWidth="7.70404" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>
			)
		// break;
		case "plug":
			return(
				<svg width="20" height="20" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M42.2642 80.7588C63.4611 80.7588 80.6436 63.5764 80.6436 42.3794C80.6436 21.1825 63.4611 4 42.2642 4C21.0672 4 3.88477 21.1825 3.88477 42.3794C3.88477 63.5764 21.0672 80.7588 42.2642 80.7588Z" stroke="black" strokeWidth="7.67588" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M49.9404 36.6227V27.0278" stroke="black" strokeWidth="7.67588" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M34.5879 36.6227V27.0278" stroke="black" strokeWidth="7.67588" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M42.2649 57.7317C46.3364 57.7317 50.2412 56.1143 53.1202 53.2353C55.9992 50.3563 57.6166 46.4515 57.6166 42.38V36.623H26.9131V42.38C26.9131 46.4515 28.5305 50.3563 31.4095 53.2353C34.2885 56.1143 38.1933 57.7317 42.2649 57.7317Z" stroke="black" strokeWidth="7.67588" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M42.2646 80.7586V57.731" stroke="black" strokeWidth="7.67588" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>
			)
		// break;
		case "location":
			return(
				<svg width="17" height="20" viewBox="0 0 72 85" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M67.4549 35.5165C67.4549 60.0294 35.9384 81.0404 35.9384 81.0404C35.9384 81.0404 4.42188 60.0294 4.42188 35.5165C4.42188 27.1578 7.74236 19.1415 13.6529 13.231C19.5634 7.32048 27.5797 4 35.9384 4C44.2971 4 52.3135 7.32048 58.224 13.231C64.1345 19.1415 67.4549 27.1578 67.4549 35.5165Z" stroke="black" strokeWidth="7.70404" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M35.9362 46.0223C41.7382 46.0223 46.4417 41.3188 46.4417 35.5167C46.4417 29.7147 41.7382 25.0112 35.9362 25.0112C30.1341 25.0112 25.4307 29.7147 25.4307 35.5167C25.4307 41.3188 30.1341 46.0223 35.9362 46.0223Z" stroke="black" strokeWidth="7.70404" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>
			)
		// break;
		default:
			break;
	}
}

const TextInput = ({ title, icon, placeholder, update }) => (
	<Cont>
		<h6>{title}</h6>
		<Box>
			{getIcon(icon)}
			{/* <Placeholder>{placeholder}</Placeholder> */}
			{/* <input type="text" value={this.state.value} onChange={this.handleChange} /> */}
			<StyledInput 
				onChange={update} 
				placeholder={placeholder} 
				type="text"
			/>
		</Box>
  	</Cont>
);

const Cont = styled.div`
	margin-top: 3rem;
`;

const Box = styled.div`
	margin-top: -1.5rem;
	padding: .8rem;
	display: flex;
	align-items: center;
	width: 100%;
	background-color: ${props=>props.theme.color.pv.lightAccent};
	border-radius: 1rem;
`;

const StyledInput = styled.input`
	width: 95%;
	padding-top:.5rem;
	margin-left: .75rem;
	background-color: ${props=>props.theme.color.pv.lightAccent};
	border: none;
	outline: none;
	// border-bottom: 1px solid ${props=>props.theme.color.pv.white};
`;

export default TextInput;

import React from 'react';
import styled from 'styled-components';

const TextInput = ({ title, test}) => (
	<Cont>
		<Box>
			{title}
		</Box>
  	</Cont>
);

const Cont = styled.div`
	// margin-top: 3rem;
	position: absolute;
	height: 100vh;
	width: 100vw;
	background-color:rgb(0,0,0,.25);
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Box = styled.div`
	padding: 3rem;
	display: flex;
	align-items: center;
	width: 50%;
	justify-content: center;	
	background-color: ${props=>props.theme.color.pv.lightAccent};
	border-radius: 1rem;
`;

export default TextInput;

// import ExternalLink from '@common/ExternalLink';
// import { Container } from '@components/global';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
// import Img from 'gatsby-image';
import React from 'react';
import { Link } from 'gatsby';
import TextInput from '@common/textInput';

function test(click) {
	let a = click
	console.log(a)
}

const Header = ({ update, click }) => (
	<StaticQuery
    query={graphql`
      query {
        art_build: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "build" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
	
    render={data => (
	<Cont>
		<Content>
			<LeftPanel>
				<HugeText>01</HugeText>
				<CoverText>Have a location in mind?</CoverText>

				<BodyTexta>fill out the information and we will be in touch.</BodyTexta>
			</LeftPanel>
			<RightPanel>
				<StyledLink to="/">
					<svg width="16" height="16" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M52.8465 28.3574H4" stroke="black" strokeWidth="6.97808" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M28.4242 52.7806L4.00098 28.3573L28.4242 3.93408" stroke="black" strokeWidth="6.97808" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
					<Shift>back</Shift>
				</StyledLink>
				<h2>Recommend A Location</h2>
				<Subtext>thanks for your interest, fill in the information below to get started</Subtext>

				<InputCont>
					<TextInput
						title="Name"
						icon="user"
						placeholder="Full Name"
						update={(e)=>update("Name", e.target.value)}
					/>
					<TextInput
						title="Email"
						icon="email"
						placeholder="your@email.com"
						update={(e)=>update("Email", e.target.value)}
					/>
					<TextInput
						title="Energy Provider"
						icon="plug"
						placeholder="Type Response"
						update={(e)=>update("EnergyProvider", e.target.value)}
					/>
					<TextInput
						title="Location"
						icon="location"
						placeholder="Address"
						update={(e)=>update("Location", e.target.value)}
					/>
				</InputCont>


				<ButtonCont onClick={()=>click()}>
					<HeaderButton>
						<StyledExternalLink to="/get-started">
							<svg width="25" height="25" viewBox="0 0 103 103" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M94.8514 47.5619V51.5678C94.8461 60.9575 91.8056 70.0939 86.1835 77.6144C80.5614 85.1349 72.6588 90.6366 63.6545 93.2989C54.6501 95.9612 45.0264 95.6415 36.2186 92.3875C27.4108 89.1334 19.8908 83.1194 14.7802 75.2424C9.66961 67.3653 7.2422 58.0473 7.86001 48.6779C8.47782 39.3086 12.1078 30.39 18.2084 23.2522C24.3091 16.1144 32.5537 11.1399 41.7125 9.07059C50.8713 7.00127 60.4537 7.94801 69.0305 11.7696" stroke="#fff" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round"/><path d="M94.8519 16.7344L51.309 60.3208L38.2461 47.2579" stroke="#fff" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round"/></svg>
							<svg width="10" height="10" viewBox="0 0 103 103" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M94.8514 47.5619V51.5678C94.8461 60.9575 91.8056 70.0939 86.1835 77.6144C80.5614 85.1349 72.6588 90.6366 63.6545 93.2989C54.6501 95.9612 45.0264 95.6415 36.2186 92.3875C27.4108 89.1334 19.8908 83.1194 14.7802 75.2424C9.66961 67.3653 7.2422 58.0473 7.86001 48.6779C8.47782 39.3086 12.1078 30.39 18.2084 23.2522C24.3091 16.1144 32.5537 11.1399 41.7125 9.07059C50.8713 7.00127 60.4537 7.94801 69.0305 11.7696" stroke={props => props.theme.color.pv.button} strokeWidth="14.4615" strokeLinecap="round" strokeLinejoin="round"/><path d="M94.8519 16.7344L51.309 60.3208L38.2461 47.2579" stroke={props => props.theme.color.pv.button} strokeWidth="14.4615" strokeLinecap="round" strokeLinejoin="round"/></svg>
							<h4>Get Started</h4>
						</StyledExternalLink>
					</HeaderButton>
				</ButtonCont>

				<Spacer>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				</Spacer>

			</RightPanel>
		</Content>
	</Cont>
    )}
  />
);


const Cont = styled.div`
	background-color: ${props => props.theme.color.pv.white};
	// background-color:red;
	// height: 100px;
	width: 100vw;
`;

const Content = styled.div`
	grid-template-columns: 2fr 5fr;
	align-items: center;
	grid-gap: 0px;
	padding: 0rem;
	display: grid;

	@media (max-Width: ${props => props.theme.screen.md}) {
		grid-template-columns: 0fr 1fr;	
	}
`;

const RightPanel = styled.div`
	overflow: scroll;
	padding: 70px 20px 0px 20px;
	height: 100vh;
	display: flex;
	align-items: start;
	flex-direction: column;
	background-color:${props => props.theme.color.pv.white};

	@media (max-Width: ${props => props.theme.screen.md}) {
		// background-color:red;
		width: 100vw;
	}

`;

const Spacer = styled.div`
	color: transparent;
	user-select: none;
`;

const LeftPanel = styled.div`
	padding: 70px 10px 0px 10px;
	background-color:${props => props.theme.color.pv.lightAccent};
	height: 100vh;

	// padding: 0rem;
	// margin: 0rem;
	// width: 0vw;
	// overflow: hidden;

	@media (max-Width: ${props => props.theme.screen.md}) {
		padding: 0rem;
		width: 0vw;
		overflow: hidden;
	}
`;

const ButtonCont = styled.div`
  justify-content: flex-end;
  display: flex;
  width: 100%
`;

const HeaderButton = styled.div`  
  margin-top: 2rem;
  box-shadow: 18px 18px 30px ${props => props.theme.color.pv.shadow};
  background: ${props => props.theme.color.pv.button};
  padding: 13px 30px 10px 5px;
  border-radius: 500px;
  width: fit-content;
  hight: 50px;
  z-index: 1;

  &:hover {
    background: ${props => props.theme.color.ls.black};
  }
`;

const StyledExternalLink = styled.div`
  user-select: none;
  color: ${props => props.theme.color.ls.white};
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding-left: 20px;

  
`;


const Shift = styled.div`
	font-size: 1rem;
	margin: .6rem 0rem .5rem .5rem;
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.color.ls.black};
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 20px 0px;

  &:hover {
    color: ${props => props.theme.color.ls.button};
  }
`;

const HugeText = styled.div`
  color: ${props => props.theme.color.ls.white};
  font-size: 20rem;

  @media (max-Width: ${props => props.theme.screen.md}) {
	width: 0px;
  }
`;

const CoverText = styled.div`
  font-size: 1rem;
  margin-top: -10rem;
  
  @media (max-Width: ${props => props.theme.screen.md}) {
	width: 0px;
  }
`;

const BodyTexta = styled.div`
  display: flex;
  font-size: 2rem;
  
  @media (max-Width: ${props => props.theme.screen.md}) {
	width: 0px;
  }
`;

const Subtext = styled.div`
  font-size: .9rem;
`;

const InputCont = styled.div`
  width: 100%
`;

export default Header;

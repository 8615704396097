import React from 'react';
import styled from 'styled-components';

const TextInput = ({ }) => (
	<Cont>
  	</Cont>
);

const Cont = styled.div`
	margin-top: 3rem;
`;

// const Box = styled.div`
// 	margin-top: -1.5rem;
// 	padding: .8rem;
// 	display: flex;
// 	align-items: center;
// 	width: 100%;
// 	background-color: ${props=>props.theme.color.pv.lightAccent};
// 	border-radius: 1rem;
// `;

// const StyledInput = styled.input`
// 	width: 95%;
// 	padding-top:.5rem;
// 	margin-left: .75rem;
// 	background-color: ${props=>props.theme.color.pv.lightAccent};
// 	border: none;
// 	outline: none;
// 	// border-bottom: 1px solid ${props=>props.theme.color.pv.white};
// `;

export default TextInput;

import { motion, AnimatePresence } from "framer-motion";
import NavbarSlim from '@common/NavbarSlim';
import React, { Component } from 'react';
import Header from '@sections/GS-Header';
import Layout from '@common/Layout';
import axios from 'axios';
import IsLoading from '@common/IsLoading';
import UserMessage from '@common/UserMessage';

class GetStarted extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
		Name: "",
		Email: "",
		EnergyProvider: "",
		Location: "",
		showOverlay: false,
		UMTitle: "",
	  }
	}
	
	update(title, data) {
		this.setState({[title]: data})
	}

	sendData() {
		if(this.state.Name === "") return "Name"
		if(this.state.Email === "") return "Email"
		if(this.state.EnergyProvider === "") return "Energy Provider"
		if(this.state.Location === "") return "Location"

		axios.post('https://prod-58.eastus2.logic.azure.com:443/workflows/4407d1de73f74c279ab7cdbcd4d48308/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=r0qsPIc5Gyj1SR9mo_3m1zH_kLQKVceUB_c4p3PFsaI', 
		{ 	Name: this.state.Name,
			Email: this.state.Email,
			EnergyProvider: this.state.EnergyProvider,
			Address: this.state.Location
		})
		.then(resp => {
			this.setState({
				Name: "",
				Email: "",
				EnergyProvider: "",
				Address: "",
				UMTitle: "thank you for your interest in Polevolt we will be in touch soon!",
				showOverlay: true
			});

			setTimeout(() => {
				this.setState({showOverlay: false})
			}, 5000);
		})
		.catch(err => {
			console.log(err);
			// this.setState({isLoading:false});
		})

		return true;
	}
  
	render() {
		return (
			<Layout>

				<AnimatePresence>{this.state.isLoading && (
					<motion.div 
					initial={{opacity: 0, scale: 1}}
					animate={{opacity: 1, scale: 1}}
					exit={{opacity: 0, scale: 1}}
					>
						<IsLoading isLoading={this.state.isLoading}/>
					</motion.div>
				)}</AnimatePresence>

				<AnimatePresence>{this.state.showOverlay && (
					<motion.div
					initial={{opacity: 0, scale: 1}}
					animate={{opacity: 1, scale: 1}}
					exit={{opacity: 0, scale: 1}}
					>
						<UserMessage 
							title={ this.state.UMTitle}
						/>
					</motion.div>
				)}</AnimatePresence>

				<NavbarSlim/>

				<Header
					update={this.update.bind(this)}
					click={this.sendData.bind(this)}
				/>

			</Layout>
		);
	}
}

export default GetStarted;
